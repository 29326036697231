/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');



/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });

////////////////////////////////////////////////////////////
/////// Register modal trigger ///////

document.addEventListener('click', function (e) {
    if (e.target && e.target.id === 'register') {
        let options = {
            'backdrop': 'static'
        };
        $('#registerModal').modal(options)
    }


});

////////////////////////////////////////////////////////////
    /////// welcome blade logged in modal trigger ///////

document.addEventListener('click', function (e) {
    if (e.target && e.target.id === 'loggedIn_modal') {
        let options = {
            'backdrop': 'static'
        };
        $('#loggedIn').modal(options)
    }


});

/////////////////////////////////
    ///redirect after login////
document.addEventListener('click', function (e) {
    if (e.target && e.target.id === 'submityes') {
        console.log('testing this');
        $(function () {

            var submityesClicked;

            //catch the click to buttons
            $('#submityes').click(function () {
                submityesClicked = true;
            });
            $('#submitno').click(function () {
                submityesClicked = false;
            });

            $('#webform').submit(function (e) {
                e.preventDefault();//prevent the default action

                $.ajax({
                    type: "POST",
                    /*url: "process.php", //process to mail
                     data: $('form.contact').serialize(),*/
                    success: function (msg) {
                        window.location.replace(submityesClicked ? "/" : "/login");
                    },
                    error: function () {
                        alert("error");
                    }
                });
            });
        });

    }
});
var el;





 $(document).ready(function() {
/**
 * for showing edit item popup
 */


////////////////////////////////////////////////////
     ///////////////// Opens AddTodo Modal//////////
     document.addEventListener('click', function (e) {
         if ( e.target && e.target.id === 'AddTodo') {
             $(this).addClass('edit-item-trigger-clicked');
             el = e.target;
             console.log('wtf mate!');

             let options = {
                 'backdrop': 'static'
             };
             $('#addTodo-modal').modal(options);
            // $("#modal-input-date").datepicker({dateFormat: 'yy-mm-dd'}).datepicker("setDate", new Date());
         }




     });


////////////////////////////////////////////
     ///// Opens Edit Modal  //////////////
document.addEventListener('click', function (e) {
    if (e.target && e.target.id === 'editModal') {
        $(this).addClass('edit-item-trigger-clicked');
        el = e.target;

        let options = {
            'backdrop': 'static'
        };
        $('#edit-modal').modal(options)
    }


});


     $(document).ready(function() {

         $('#edit-modal').on('show.bs.modal', function () {
             if (el && el.id === 'editModal') {
             var row = el.parentNode.closest(".listItems");
             console.log(row.childNodes);

             var id = el.getAttribute('ids');
             var title = row.childNodes[1].textContent;
             var description = row.childNodes[3].textContent;
             var dueDate = row.childNodes[16].textContent;
             console.log(dueDate);

            console.log(id,title, description);
             // fill he data in the input fields
             $("#modal-input-id").val(id);
             $("#modal-input-name").val(title);
             $("#modal-input-description").val(description);
                 $('#modal-input-date').datepicker('setDate',new Date(dueDate));

         }
             el = '';
         })

     });


// On Modal Hide!
$('#edit-modal').on('hide.bs.modal', function() {

    $('.edit-item-trigger-clicked').removeClass('edit-item-trigger-clicked');
    $("#edit-form").trigger("reset");
  el = '';
})
 });


//
// $('#modal-input-date').datepicker({
//     Format: 'dd/mm/yy'
// });







////////////////////////////////////////
//////Archive modal trigger ////////////

$(document).ready(function() {

    document.addEventListener('click', function (e) {
        if (e.target && e.target.id === 'archiveButton') {
            console.log('what the fuck mang');
            el = e.target;

            let options = {
                'backdrop': 'static'
            };
            $('#archive-modal').modal(options)
        }


    });

    $(document).ready(function () {

        $('#archive-modal').on('show.bs.modal', function () {
            if (el && el.id === 'archiveButton') {

                var id = el.getAttribute('data-id');


                $("#modal-input-ids").val(id);
            }
        })
    })

});


////////////////////////////////////////////////
/// Add category modal /////////////////////

$(document).ready(function() {

    document.addEventListener('click', function (e) {
        if (e.target && e.target.id === 'createCat') {
            el = e.target;

            let options = {
                'backdrop': 'static'
            };
            $('#category-modal').modal(options)
        }


    });

});

/////////////////////////////////////////////////////////////
// add new asset modal //////////////////////////////////////

$(document).ready(function() {

    document.addEventListener('click', function (e) {
        if (e.target && e.target.id === 'cat-card-names') {
            el = e.target;
            let options = {
                'backdrop': 'static'
            };
            $('#add-Asset').modal(options)
        }


    });

    $(document).ready(function () {

        $('#add-Asset').on('show.bs.modal', function () {
            if (el && el.id === 'cat-card-names') {

                var category = el.getAttribute('data-category');
            console.log('this thing on?');
               console.log(category);
               // category = category.replace('s','');
               //
                $("#data-category-name").html(category);
                $("#data-category-inputs").val(category);
                $("#data-cat").val(category);


            }
        })
    });

});

///////////////////////////////////////////////////////////
////////// Edit Asset modal trigger ///////////////////////////////////

$(document).ready(function() {

    document.addEventListener('click', function (e) {
        if (e.target && e.target.id === 'edit') {
            el = e.target;
            let options = {
                'backdrop': 'static'
            };
            $('#edit-asset').modal(options)
        }


    });

    $(document).ready(function () {

        $('#edit-asset').on('show.bs.modal', function () {
            if (el && el.id === 'edit') {
                console.log(el);
                 var row = el.parentNode.closest('.assetRow');
                  console.log(row.childNodes);
                var serial = row.childNodes[3].textContent;
                var model = row.childNodes[1].textContent;
                var notes = row.childNodes[9].textContent;
                var cat = row.childNodes[7].textContent;
                var itemId = row.childNodes[13].textContent;
                var cost = row.childNodes[11].textContent;
                var date = row.childNodes[5].textContent;

               console.log(itemId);
               console.log(row);

                $("#model").val(model);
                $("#serialNumber").val(serial);
                $("#notes").val(notes);
                $("#data-category-input").val(cat);
                $('#itemId').val(itemId);
                $('#cost').val(cost);
                $('#datePurchased').datepicker('setDate',new Date(date));



            }
        })
    });

$('#edit-asset').on('hide.bs.modal', function() {

    $('.edit-item-trigger-clicked').removeClass('edit-item-trigger-clicked');
    $("#edit-form").trigger("reset");
    el = '';
})
});


////////////////////////////////////////////////////////
////// Delete Asset Modal /////////////////////////////


$(document).ready(function() {

    document.addEventListener('click', function (e) {
        if (e.target && e.target.id === 'deleteAsset') {
            el = e.target;

            let options = {
                'backdrop': 'static'
            };
            $('#deleteAsset-modal').modal(options)
        }


    });

    $(document).ready(function () {

        $('#deleteAsset-modal').on('show.bs.modal', function () {
            if (el && el.id === 'deleteAsset') {
                console.log(el);
                var row = el.parentNode.closest('.assetRow');
                var itemId = row.childNodes[13].textContent;
                var cat = row.childNodes[7].textContent;
                console.log(itemId,cat);

                $("#modal-input-ids").val(itemId);
                $("#asset-id").val(itemId);
                $("#data-category").val(cat);
            }
        })
    })

});

///////////////////////////////////////////////////////
////// Points dashboard modal //////////////////////

$(document).ready(function() {
    document.addEventListener('click', function (e) {
        if (e.target && e.target.id === 'add-points') {
            el = e.target;
           console.log("Wild card bitches~!~!")
            let options = {
                'backdrop': 'static'
            };
            $('#Add-points').modal(options)
        }
    });
});
$(document).ready(function() {
    document.addEventListener('click', function (e) {
        if (e.target && e.target.id === 'spend-points') {
            el = e.target;
            let options = {
                'backdrop': 'static'
            };
            $('#Spend-points').modal(options)
        }
    });
});

///////////// Points dashboard JS  ///////////////////////


Chart.defaults.global.defaultFontColor = 'white';
Chart.defaults.global.borderColor = 'white';
Chart.defaults.global.hoverBorderColor = 'white';

$( document ).ready(function() {
    var ctx = document.getElementById('myChart');
    var ctx3 = document.getElementById('myChart3');
    var ctx2 = document.getElementById('myChart2');
    const beer = document.getElementById('beer').innerHTML;
    const fastfb = document.getElementById('fastfb').innerHTML;
    const fastfd = document.getElementById('fastfd').innerHTML;
    const other = document.getElementById('other').innerHTML;
    const diets = document.getElementById('diets').innerHTML;
    const weights = document.getElementById('weights').innerHTML;
    const bike = document.getElementById('bike').innerHTML;
    const program = document.getElementById('program').innerHTML;
    const dietsm = document.getElementById('dietsm').innerHTML;
    const weightsm = document.getElementById('weightsm').innerHTML;
    const bikem = document.getElementById('bikem').innerHTML;
    const programm = document.getElementById('programm').innerHTML;
    const cleanedm = document.getElementById('cleanedm').innerHTML;
    const cleaned = document.getElementById('cleaned').innerHTML;
 //   const cleaned7 = document.getElementById('cleaned7').innerHTML;

    const options = {
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem) {
                return tooltipItem.yLabel;
            }
        }
    }
}

   var myChart =  new Chart(ctx, {
        type: 'doughnut',
       legend:{
            display:true,
           labels:["#FFFFFF"],
       },
        data: {
            labels: [  'Beer',
                'Fast Food Breakfast',
                'Fast Food Dinner','Other'
            ],


            datasets: [{
                backgroundColor: ["#00D2A3", "#D350D9","#F95176","#FF7E7E","#c45850"],
                data: [beer,fastfb,fastfd,other],

                borderWidth:2,
                defaultFontSize:15
            }],

        },

    });

    var myChart2 = new Chart(ctx2, {
        type: 'bar',

        data: {

            labels: ['Diet','Weights','Bike','Program','Cleaned'],
            datasets: [{
                label:'Points added',
                data: [diets,weights,bike,program,cleaned],
                backgroundColor: [
                    '#00FF66',
                    "#0033FF","#CC00FF","#9900FF","#FFFF00"
                ],
                borderColor:["white","#000000","white","#000000","white"],
                // borderColor: [
                //     'rgba(255, 159, 64, 1)',
                //     'rgba(255, 159, 64, 1)',
                //     'rgba(255, 159, 64, 1)',
                //     'rgba(255, 159, 64, 1)',
                //     'rgba(255, 159, 64, 1)',
                //     'rgba(255, 159, 64, 1)'
                // ],
                borderWidth:2,
                defaultFontSize:15
            }]
        },
        options:options
    });

    var myChart3 =  new Chart(ctx3, {
        type: 'pie',
        legend:{
            display:true,
            labels:["#FFFFFF"],
        },
        data: {
            labels:['Diet','Weights','Bike','Program','Cleaned'],


            datasets: [{
                backgroundColor: [
                    '#00FF66',
                    "#0033FF","#CC00FF","#9900FF","#FFFF00"
                ],
                data: [dietsm,weightsm,bikem,programm,cleanedm],

                borderWidth:2,
                defaultFontSize:15
            }],

        },

    });





    // var myChart3 = new Chart(ctx3, {
    //     type: 'pie',
    //     legend:{
    //         display:true,
    //         labels:['Diet','Weights','Bike','Program','Cleaned'],
    //     },
    //     data: {
    //
    //
    //         datasets: [{
    //             labels: ['Diet','Weights','Bike','Program','Cleaned'],
    //             data: [dietsm,weightsm,bikem,programm,cleanedm],

    //             borderColor:["#000000","#000000","#000000","#000000","#000000"],
    //             // borderColor: [
    //             //     'rgba(255, 159, 64, 1)',
    //             //     'rgba(255, 159, 64, 1)',
    //             //     'rgba(255, 159, 64, 1)',
    //             //     'rgba(255, 159, 64, 1)',
    //             //     'rgba(255, 159, 64, 1)',
    //             //     'rgba(255, 159, 64, 1)'
    //             // ],
    //             borderWidth:2,
    //             defaultFontSize:15
    //         }]
    //     },
    //     options:options
    // });

    $(document).ready(function () {
        $('input:checkbox').click(function () {
            $('input:checkbox').not(this).prop('checked', false);
        });
    })
});
/////////////// example line chart ///////////
var myChart4 = new Chart(document.getElementById("line-chart"), {
    type: 'line',
    data: {
        labels: [1500,1600,1700,1750,1800,1850,1900,1950,1999,2050],
        datasets: [{
            data: [86,114,106,106,107,111,133,221,783,2478],
            label: "Africa",
            borderColor: "#3e95cd",
            fill: false
        }, {
            data: [282,350,411,502,635,809,947,1402,3700,5267],
            label: "Asia",
            borderColor: "#8e5ea2",
            fill: false
        }, {
            data: [168,170,178,190,203,276,408,547,675,734],
            label: "Europe",
            borderColor: "#3cba9f",
            fill: false
        }, {
            data: [40,20,10,16,24,38,74,167,508,784],
            label: "Latin America",
            borderColor: "#e8c3b9",
            fill: false
        }, {
            data: [6,3,2,2,7,26,82,172,312,433],
            label: "North America",
            borderColor: "#c45850",
            fill: false
        }
        ]
    },
    options: {
        title: {
            display: true,
            text: 'World population per region (in millions)'
        }
    }
});

